<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Quốc gia
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="4">
              <CSelect label="Trạng thái" horizontal :options="optionsTrangThai" :value.sync="filterModel.trangThai"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="4">
              <CInput label="Từ khóa" horizontal :value.sync="filterModel.keyword"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12">
              <CButton class="mb-3 mr-3" color="primary" :disabled="isFetchingList" @click="search">
                <CIcon name="cil-search"/>
                Tìm kiếm
              </CButton>
              <CButton class="mb-3" color="primary" @click="createItem" v-if="permissions.isAdd">
                <CIcon name="cil-plus"/>
                Thêm mới
              </CButton>
            </CCol>
          </CRow>
          <div class="position-relative table-responsive">
            <table id="tbNganhLinhVucKeuGoiDauTu" class="table table-striped table-fixed table-hover table-bordered">
              <thead>
              <tr>
                <th class="position-relative pr-4">STT</th>
                <th class="position-relative pr-4">Mã ngành Quốc gia</th>
                <th class="position-relative pr-4">Tên quốc gia</th>
                <th class="position-relative pr-4">Thời gian cập nhật</th>
                <th class="position-relative pr-4">Trạng thái</th>
                <th class="position-relative pr-4">Thao tác</th>
              </tr>
              </thead>
              <tbody class="position-relative">
              <template v-if="dataSourcePaging.items">
                <tr v-for="(item, index) in dataSourcePaging.items" :key="item.id">
                  <td>{{ index + firstItemIndex + 1 }}</td>
                  <td>{{ item.ma }}</td>
                  <td>{{ item.ten }}</td>
                  <td>{{ item.createdDate | formatDate }}</td>
                  <td>{{ displayLabel(optionsTrangThai, item.trangThai) }}</td>
                  <td>
                    <CButton size="sm" color="info" class="mr-2" @click="viewItem(item)" title="Chi tiết">
                      <CIcon name="cil-options"/>
                    </CButton>
                    <CButton size="sm" color="primary" class="mr-2" @click="updateItem(item)" title="Chỉnh sửa"
                             v-if="permissions.isUpdate">
                      <CIcon name="cil-pencil"/>
                    </CButton>
                    <CButton size="sm" color="danger" @click="destroyItem(item)" title="Xóa"
                             v-if="permissions.isDelete">
                      <CIcon name="cil-x"/>
                    </CButton>
                  </td>
                </tr>
              </template>
              <tr v-if="dataSourcePaging.items && dataSourcePaging.items.length === 0 && !isFetchingList">
                <td colspan="8">
                  <slot name="no-items-view">
                    <div class="text-center my-5">
                      <h2>
                        Không có dữ liệu
                        <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                      </h2>
                    </div>
                  </slot>
                </td>
              </tr>
              </tbody>
            </table>
            <CElementCover v-if="isFetchingList"
                           :boundaries="[{ sides: ['top'], query: 'td' },{ sides: ['bottom'], query: 'tbody' }]"/>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <DeleteConfirmationModal
      title="Xóa quốc gia"
      :show="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="deletedItem" @confirm-destroy="confirmDestroy" @update:show="cancelDestroy"/>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/modules/quoc-gia/actionTypes'
import { statuses, displayLabel } from '@/shared/appConstants'

export default {
  name: 'QuocGia',
  data () {
    return {
      filterModel: {
        keyword: '',
        trangThai: null,
        orderBy: null,
        pageNumber: 1,
        pageSize: 15
      },
      optionsTrangThai: statuses,
      deletedItem: {},
      isDeleteConfirmationModalOpen: false
    }
  },
  computed: {
    ...mapGetters('quocGia', {
      dataSourcePaging: 'dataSourcePaging',
      isFetchingList: 'isFetchingList',
      responseResult: 'responseResult',
      isDeleting: 'isDeleting'
    }),
    ...mapGetters('auth', {
      permissions: 'permissionsDmQuocGia'
    }),
    firstItemIndex () {
      return (this.filterModel.pageNumber - 1) * this.filterModel.pageSize || 0
    }
  },
  methods: {
    ...mapActions('quocGia', {
      getListPaging: types.GET_LIST_QUOCGIA_PAGING,
      deleteItem: types.DELETE_QUOCGIA
    }),
    displayLabel,
    async fetchListItem () {
      await this.getListPaging(this.filterModel)
    },
    async search () {
      this.filterModel.pageNumber = 1
      this.filterModel.orderBy = null
      await this.fetchListItem()
    },
    createItem () {
      this.$router.push({ path: 'quoc-gia/them-moi' })
    },
    viewItem (item) {
      this.$router.push({ path: `quoc-gia/${item.id}` })
    },
    updateItem (item) {
      this.$router.push({ path: `quoc-gia/${item.id}/cap-nhat` })
    },
    destroyItem (item) {
      this.deletedItem = Object.assign({}, item)
      this.isDeleteConfirmationModalOpen = true
    },
    cancelDestroy () {
      this.deletedItem = {}
      this.isDeleteConfirmationModalOpen = false
    },
    async confirmDestroy () {
      await this.deleteItem(this.deletedItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        this.deletedItem = {}
        await this.fetchListItem()
      }
    }
  },
  async mounted () {
    await this.fetchListItem()
  }
}
</script>
